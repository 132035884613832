import React from "react";
import "styled-components";
import en from "../locales/en.yml";
import fi from "../locales/fi.yml";
import theme from "../styles/theme";
import ImageGallery from "./ImageGallery";
import { Coiniverse } from "./Svgs";

const CoinItem = ({ data, locale: localeInit, onClose }) => {
  const locale = localeInit === "fi-FI" ? "fi" : "en";
  const text = locale === "en" ? en : fi;

  const series =
    data.series === "Presidents of Finland"
      ? text.filters.options.series.presidentsOfFinland
      : data.series === "Animals of the Provinces"
      ? text.filters.options.series.animalsOfProvinces
      : data.series === "Finnish National Landscapes"
      ? text.filters.options.series.finnishLanscapes
      : data.series === "Finnish Sports"
      ? text.filters.options.series.sports
      : null;

  const material =
    data.characteristicMaterial === "Base metal"
      ? text.coin.basicMetal
      : data.characteristicMaterial === "Silver"
      ? text.coin.silver
      : data.characteristicMaterial === "Gold"
      ? text.coin.gold
      : null;

  const images = data.imagesCollection.items.map(item => ({
    original: `${item.imageUrl}`,
    thumbnail: `${item.imageUrl}`,
  }));

  const designers = data.designer?.join(", ");

  return (
    <>
      <article
        css={`
          display: flex;
          flex-direction: column;
          padding-top: 50px;
          .row {
            display: flex;
          }
          .row:first-of-type {
            margin-bottom: 50px;
          }
          .left,
          .right {
            flex: 1;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
          }
          .left {
            width: 50%;
            padding-top: 20px;
          }
          .right {
            padding-left: 30px;
          }
          p {
            line-height: 1.5;
          }
          h1 {
            font-size: 42px;
            margin: 20px 0 20px;
          }
          h2 {
            font-family: nimbus-sans;
            font-weight: 700;
            font-size: 24px;
          }
          h3 {
            font-family: nimbus-sans;
            font-weight: 700;
            font-size: 16px;
            text-transform: uppercase;
            letter-spacing: 1px;
            opacity: 0.5;
          }
          .btnCoiniverse {
            display: none;
            margin: 30px 0;
          }
          .imgBox {
            width: 100%;
            margin: 0 auto;
            background: #fff;
            border-radius: 10px;
          }
          .imgCoin {
            width: 100%;
          }
          .image-gallery {
            width: 90%;
            max-width: 500px;
            margin: 0 auto;
          }
          .image-gallery-thumbnails-container {
            margin: 40px;
            min-height: 200px;
            button.active,
            button:hover {
              border: none;
            }
          }
          .sweetLoader {
            position: absolute;
            width: 100%;
            top: 20%;
            left: 0;
            right: 0;
            text-align: center;
            opacity: 0.4;
          }
          .mobileTitle {
            display: none;
          }
          .mintagesTitle,
          .mintagesBox {
            display: none;
          }
          .coinPlaceholder {
            animation: linear 1s placeHolderShimmer infinite;
            background: #f6f7f8;
            background: linear-gradient(
              to right,
              #eeeeee 8%,
              #dddddd 18%,
              #eeeeee 33%
            );
            background-size: 800px 104px;
            width: 100%;
            padding-bottom: 100%;
            border-radius: 50%;
            transform: translateZ(0);
          }
          @keyframes placeHolderShimmer {
            0% {
              background-position: -468px 0;
            }
            100% {
              background-position: 468px 0;
            }
          }
          ${theme.tablet} {
            .row:first-of-type {
              margin-bottom: 20px;
            }
            .desktopTitle {
              display: none;
            }
            .mobileTitle {
              display: flex;
              h1 {
                margin-top: 30px;
              }
            }
            .row {
              flex-wrap: wrap;
              > div {
                flex-basis: 100%;
              }
            }
          }
          ${theme.mobile} {
            .row:first-of-type {
              justify-content: center;
            }
            h1 {
              font-size: 32px;
              margin-top: 40px;
              text-align: center;
            }
            .left {
              padding-left: 0;
              padding-right: 0;
              max-width: 100%;
              ${images.length > 3 && "padding-bottom: 80px;"}
            }
            .right {
              padding-left: 0;
            }
          }
        `}
      >
        {data.coinid && (
          <>
            <div className="row mobileTitle">
              <h1> {data.title}</h1>
            </div>
            <div className="row">
              <div className="left">
                <div className="imgBox">
                  <ImageGallery data={images} />
                </div>
              </div>
              <div className="right">
                <h1 className="desktopTitle">{data.title}</h1>
                <p
                  css={`
                    white-space: pre-wrap;
                  `}
                  dangerouslySetInnerHTML={{
                    __html: data.descriptionText,
                  }}
                />
                <div
                  className="btnCoiniverse"
                  css={`
                    .button {
                      display: inline-flex;
                      height: auto;
                      display: flex;
                      align-items: center;
                      height: 48px;
                      border-radius: 24px;
                      padding-right: 20px;
                      svg {
                        margin: 0 10px;
                      }
                    }
                    .downloadIcons {
                      display: inline-flex;
                      display: none;
                      img {
                        max-height: 50px;
                        margin: 10px;
                      }
                    }
                  `}
                >
                  <button className="button">
                    <Coiniverse />
                    <span>{text.single.openApp}</span>
                  </button>
                  <div className="downloadIcons">
                    <a
                      href="https://apps.apple.com/fi/app/coiniverse/id1538929667?l=fi"
                      target="_blank"
                    >
                      <img src="/appstore.png" />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?gl=US&hl=fi&id=fi.rahapaja.coiniverse"
                      target="_blank"
                    >
                      <img src="/play.png" />
                    </a>
                  </div>
                </div>

                <h2>{text.single.features}</h2>
                <table
                  css={`
                    border-collapse: collapse;
                    min-width: 100%;
                    max-width: 400px;
                    margin-bottom: 40px;
                    tr {
                      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    }

                    td {
                      padding: 6px 0;
                      width: 50%;
                    }
                  `}
                >
                  <tbody>
                    <tr>
                      <td>{text.single.year}</td>
                      <td>{data.years}</td>
                    </tr>
                    <tr>
                      <td>{text.single.faceValue}</td>
                      <td>{data.faceValue}</td>
                    </tr>
                    <tr>
                      <td>{text.single.type}</td>
                      <td>
                        {data.commemorative === "1"
                          ? text.single.commemorative
                          : text.single.spendingMoney}
                      </td>
                    </tr>
                    {designers && (
                      <tr>
                        <td>
                          {data.designer.length > 1
                            ? text.single.designers
                            : text.single.designer}
                        </td>
                        <td>{designers}</td>
                      </tr>
                    )}
                    {material && (
                      <tr>
                        <td>{text.single.material}</td>
                        <td>{material}</td>
                      </tr>
                    )}
                    {data.characteristicMetal && (
                      <tr>
                        <td>{text.single.metal}</td>
                        <td>{data.characteristicMetal}</td>
                      </tr>
                    )}
                    {data.characteristicWeight && (
                      <tr>
                        <td>{text.single.weight}</td>
                        <td>{data.characteristicWeight} g</td>
                      </tr>
                    )}
                    {data.characteristicDiameter && (
                      <tr>
                        <td>{text.single.diameter}</td>
                        <td>{data.characteristicDiameter} mm</td>
                      </tr>
                    )}
                    {data.characteristic?.thickness3 && (
                      <tr>
                        <td>{text.single.thickness}</td>
                        <td>{data.characteristicThickness} mm</td>
                      </tr>
                    )}
                    {series && (
                      <tr>
                        <td>{text.single.series}</td>
                        <td>{series}</td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <h2 className="mintagesTitle">{text.single.mintages.title}</h2>
                <table
                  className="mintagesBox"
                  css={`
                    border-collapse: collapse;
                    min-width: 100%;
                    max-width: 400px;
                    tr {
                      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    }
                    th {
                      text-align: left;
                      width: 20%;
                      padding: 6px 0;
                    }
                    td {
                      padding: 6px 0;
                    }
                  `}
                >
                  <tbody>
                    <tr>
                      <th>{text.single.mintages.year}</th>
                      <th>{text.single.mintages.unc}</th>
                      <th>{text.single.mintages.bu}</th>
                      <th>{text.single.mintages.proof}</th>
                    </tr>
                    {/*data.mintages.map(item => {
                          return (
                            <tr key={item.year + item.mintage}>
                              <td>{item.year}</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>{item.mintage}</td>
                            </tr>
                          );
                        })*/}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className="row"
              css={`
                justify-content: center;
                margin: 0 0 40px;
              `}
            >
              <button
                onClick={e => {
                  e.preventDefault();
                  onClose();
                }}
              >
                {text.single.goBack}
              </button>
            </div>
          </>
        )}
      </article>
    </>
  );
};

export default CoinItem;
