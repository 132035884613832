import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { graphql, Link } from "gatsby";
import React, { useEffect, useState } from "react";
import "styled-components";
import CoinItem from "../components/CoinItem";
import Filters from "../components/Filters";
import ImgItem from "../components/ImgItem";
import Layout from "../components/Layout";
import Modal from "../components/Modal";
import heroImg from "../images/mint_hero.jpg";
import en from "../locales/en.yml";
import fi from "../locales/fi.yml";
import theme from "../styles/theme";

// Keep item img ratio
const sortByYears = (a, b) => {
  let aYear = a.years?.slice(0, 4) || 10_000;
  let bYear = b.years?.slice(0, 4) || 10_000;

  if (aYear > bYear) return -1;
  if (aYear < bYear) return 1;
  if (aYear === bYear) return 0;
};

const sortByValue = (a, b) => {
  let aValue = +a.faceValue?.replace(",", ".");
  let bValue = +b.faceValue?.replace(",", ".");

  if (aValue > bValue) return 1;
  if (aValue < bValue) return -1;
  if (aValue === bValue) return 0;
};

const Index = ({ data, pageContext }) => {
  const locale = pageContext.locale;
  const text = pageContext.locale === "fi-FI" ? fi : en;

  const coinsInitial = [...data.contentful.coins.items].sort(sortByYears);

  const selectionsTemplate = {
    currency: "",
    currencyHidden: "",
    faceValue: "",
    series: "",
    type: "",
    material: "",
    startYear: null,
    endYear: null,
    sortBy: "yearsDesc",
  };

  let initialSelections = selectionsTemplate;
  let initialShowCoins = 1;

  if (typeof window !== "undefined") {
    initialSelections =
      JSON.parse(sessionStorage.getItem("selections")) || selectionsTemplate;
    initialShowCoins = JSON.parse(sessionStorage.getItem("showCoins")) || 1;

    // Strip Serie parameter from URL
    if (window.location.href.includes("?serie=") && locale == "fi-FI") {
      window.history.pushState("data to be passed", "Title of the page", "/");
    }
    if (window.location.href.includes("?serie=") && locale == "en-US") {
      window.history.pushState("data to be passed", "Title of the page", "/en");
    }
  }

  const [showCoins, setShowCoins] = useState(initialShowCoins);
  const [selections, setSelections] = useState(initialSelections);

  const [showFilters, setShowFilters] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [singleCoin, setSingleCoin] = useState(null);

  const { etusivu } = data.contentful;

  const clearFilters = () => {
    setSelections({
      currency: "",
      currencyHidden: "",
      faceValue: "",
      series: "",
      type: "",
      material: "",
      startYear: null,
      endYear: null,
      sortBy: selections.sortBy,
    });
  };

  const handleSelect = (values, key) => {
    if (key === "faceValue") {
      setSelections({
        ...selections,
        currencyHidden: values[0]?.value.currency || "",
        faceValue: values[0]?.value.faceValue || "",
      });
      return;
    }
    if (key === "currency") {
      setSelections({
        ...selections,
        currencyHidden: values[0]?.value || "",
        currency: values[0]?.value || "",
      });
      return;
    }
    setSelections({ ...selections, [key]: values[0]?.value || "" });
  };

  const filterCoins = coins => {
    let newCoins = [...coins];

    if (selections.startYear) {
      newCoins = newCoins.filter(item => {
        let str = item.years || "2000";
        const year = str.slice(str.length - 4);
        return year >= selections.startYear;
      });
    }
    if (selections.endYear) {
      newCoins = newCoins.filter(item => {
        const year = item.years?.slice(0, 4);
        return year <= selections.endYear;
      });
    }
    if (selections.currencyHidden) {
      newCoins = newCoins.filter(item => {
        return item.currencyDropdown === selections.currencyHidden;
      });
    }
    if (selections.series) {
      newCoins = newCoins.filter(item => {
        return (
          item.seriesRefCollection.items[0]?.serieName === selections.series
        );
      });
    }
    if (selections.faceValue) {
      newCoins = newCoins.filter(item => {
        let str = selections.faceValue.toString().replace(".", ",");
        return item?.faceValue == str;
      });
    }
    if (selections.type === "Käyttöraha") {
      newCoins = newCoins.filter(item => {
        return item.commemorative !== 1;
      });
    }
    if (selections.type === "Juhlaraha") {
      newCoins = newCoins.filter(item => {
        return item.commemorative == 1;
      });
    }
    if (selections.material) {
      newCoins = newCoins.filter(item => {
        return item.characteristicMaterial === selections.material;
      });
    }
    if (selections.sortBy === "yearsAsc") {
      newCoins = newCoins.sort(sortByYears);
      newCoins = [...newCoins.reverse()];
    }
    if (selections.sortBy === "valueDesc") {
      newCoins = newCoins.sort(sortByValue).reverse();
    }
    if (selections.sortBy === "valueAsc") {
      newCoins = newCoins.sort(sortByValue);
    }

    return newCoins;
  };

  useEffect(() => {
    sessionStorage.setItem("selections", JSON.stringify(selections));
    sessionStorage.setItem("showCoins", JSON.stringify(showCoins));
  }, [selections, showCoins]);

  useEffect(() => {
    // Check for parameters for series filtering
    const url = new URL(window.location.href);
    const filterSerieParam = url.searchParams.get("serie");

    if (filterSerieParam) {
      setSelections({ ...selections, series: filterSerieParam || "" });
    }
  }, []);

  const allCoins = filterCoins(coinsInitial);
  const allCoinsLength = allCoins.length;
  const coins = allCoins.slice(0, 16 * showCoins);

  const handleShowMore = event => {
    setIsUpdating(true);
    setShowCoins(showCoins + 1);
    setIsUpdating(false);
    event.preventDefault();
  };

  const handleBackToTop = event => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    event.preventDefault();
  };

  // Parse series for filter
  const seriesToFilter = data.contentful.series.items.filter(
    item => item.useInFilters
  );

  const series = seriesToFilter.map(item => {
    return {
      value: item.nameEn,
      label: pageContext.locale === "fi-FI" ? item.nameFi : item.nameEn,
    };
  });

  return (
    <Layout
      locale={locale}
      title={text.title}
      langLinks={{ fi: "/", en: "/en" }}
    >
      {singleCoin && (
        <Modal onClose={() => setSingleCoin(null)}>
          <CoinItem
            data={coinsInitial.find(item => item.coinid.id === singleCoin)}
            locale={locale}
            onClose={() => setSingleCoin(null)}
          />
        </Modal>
      )}
      <main>
        <section
          css={`
            ${theme.fullWidth}
            background: #FAFAFA;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 500px;
            text-align: center;
            background-image: url(${heroImg});
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            .layer {
              background: inherit;
              width: 100%;
              height: 100%;
              position: absolute;
              background: rgba(0, 0, 0, 0.2);
            }
            .content {
              padding: 40px 0;
              position: relative;
              color: #fff;
            }
            h1 {
              font-size: 52px;
              margin: 16px 0 10px;
            }
            .shortHeading {
              text-transform: uppercase;
              letter-spacing: 2px;
              padding-bottom: 4px;
              font-weight: 300;
              font-size: 19px;
            }
            p {
              font-size: 19px;
              max-width: 720px;
              line-height: 1.6;
            }
            ${theme.mobile} {
              .content {
                padding: 120px 10px 80px;
              }
              h1 {
                font-size: 38px;
              }
              p,
              .shortHeading {
                font-size: 18px;
              }
            }
          `}
        >
          <div className="layer" />
          <div className="content">
            <span className="shortHeading">{etusivu.heroYlotsikko}</span>
            <h1>{etusivu.heroOtsikko}</h1>
            <div
              css={`
                display: block;
              `}
              dangerouslySetInnerHTML={{
                __html: documentToHtmlString(etusivu.heroIngressi.json),
              }}
            />
          </div>
        </section>
        <section
          css={`
            flex-direction: column;
            padding-top: 40px;
            padding-bottom: 80px;
            .btnBox {
              margin: 0 auto;
            }
            .sortSelectBox {
              margin-top: -39px;
              margin-left: auto;
              display: flex;
              align-items: center;
            }
            .sortSelect {
              width: 220px;
              margin-top: 0;
            }
            #lonelySpinner {
              margin-right: 10px;
            }
            h3 {
              font-size: 22px;
              font-family: "freight-text-pro";
              margin-bottom: 20px;
              text-align: center;
            }

            .filters {
              margin-top: 10px;
            }
            ${theme.mobile} {
            }
          `}
        >
          <h3>{text.filters.title}</h3>

          <div className="filters">
            <Filters
              selections={selections}
              handleSelect={handleSelect}
              text={text}
              clearFilters={clearFilters}
              series={series}
            />
          </div>

          <h3>
            {allCoinsLength}{" "}
            {allCoinsLength === 1 ? text.ui.coin : text.ui.coins}
          </h3>

          <div
            className="gallery"
            css={`
              margin: 10px 0 80px;
              display: grid;
              min-height: 400px;
              justify-content: center;
              grid-gap: 20px;
              grid-template-columns: repeat(4, 1fr);
              position: relative;
              ${theme.tablet} {
                grid-template-columns: repeat(3, 1fr);
              }
              ${theme.mobile} {
                grid-template-columns: repeat(1, 1fr);
              }
              .item {
                border-radius: 10px;
                background: #fff;
                box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.04);
                overflow: hidden;
                display: flex;
                flex-direction: column;
                transition: all 0.2s;
                border: 1px solid rgba(100, 100, 100, 0.04);
                cursor: pointer;
                a {
                  padding: 20px 0 0;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  height: 100%;
                }
                .imgCoin {
                  margin-bottom: 0;
                  max-width: 100%;
                }
                &:hover {
                  box-shadow: 8px 8px 30px rgba(0, 0, 0, 0.18);
                }
              }
              h2 {
                margin: 20px 0 0;
                font-size: 20px;
                text-align: left;
                padding: 0 20px;
                min-height: 62px;
              }
              p {
                font-family: freight-text-pro, serif;
                text-align: center;
                line-height: 1.4;
                color: #646464;
              }
              .topContent {
              }
              .middleContent {
                display: flex;
                padding: 20px;
                flex: 1;
                position: relative;
                justify-content: center;
                align-items: center;
                min-height: 240px;
              }
              .bottom {
                background: #f2f2f2;
                width: 100%;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                font-family: nimbus-sans;
              }
              .bottomContent {
                padding: 10px 20px 20px;
                width: 100%;
                flex-direction: column;
                justify-content: center;
                display: grid;
                grid-gap: 5px;
                grid-template-columns: repeat(2, 1fr);
                p.gridItem {
                  font-family: nimbus-sans, sans-serif;
                  font-size: 14px;
                  text-align: left;
                  margin: 0;
                }
              }

              .noResults {
                margin: 60px auto 80px;
                p {
                  text-align: center;
                }
              }
            `}
          >
            {coins &&
              coins.length > 0 &&
              coins.map(item => {
                const currency =
                  item.currencyDropdown === "euro"
                    ? text.coin.euro
                    : item.currencyDropdown === "markka"
                    ? text.coin.marks
                    : null;

                const type =
                  item.commemorative == 1
                    ? text.coin.commemorative
                    : text.coin.spendingMoney;

                const value = `${item.faceValue} ${currency}`;
                const title = item.title;
                const itemMaterial =
                  item.characteristicMaterial === "Base metal"
                    ? text.coin.basicMetal
                    : item.characteristicMaterial === "Silver"
                    ? text.coin.silver
                    : item.characteristicMaterial === "Gold"
                    ? text.coin.gold
                    : null;

                const facts = [value, item.years, itemMaterial, type];

                const imgData = item.imagesCollection.items[0].imageUrl;
                let imgUrl;
                if (imgData.includes("wp-content")) {
                  imgUrl = imgData.includes("scaled")
                    ? imgData.replace("scaled", "300x300")
                    : imgData.replace(".jpg", "-300x300.jpg");
                } else {
                  imgUrl = imgData;
                }

                if (item.featureImage) {
                  imgUrl = item.featureImage;
                }

                let slug =
                  locale === "fi-FI"
                    ? `kolikko/${item.slug}`
                    : `coin/${item.slug}`;

                return (
                  <Link to={slug} className="item" key={item.coinid}>
                    <div className="middleContent">
                      <ImgItem key={imgUrl} data={imgUrl} />
                    </div>
                    <div className="bottom">
                      <h2>
                        {title}{" "}
                        {item.commemorative == 1 && (
                          <>
                            {item.faceValue}&nbsp;{currency}
                          </>
                        )}
                      </h2>
                      <div className="bottomContent">
                        {facts.map(item => {
                          return (
                            <p key={item} className="gridItem">
                              {item}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  </Link>
                );
              })}
          </div>

          <div
            className="btnBox"
            css={`
              display: flex;
              flex-direction: column;
              .backToTop {
                margin-top: 15px;
                padding: 10px;
                color: #000;
                text-decoration: underline;
              }
              .disable {
                opacity: 0.15;
                pointer-events: none;
                cursor: default;
              }
            `}
          >
            <button
              className={
                showCoins * 16 < allCoins.length ? "peaceandlove" : "disable"
              }
              onClick={handleShowMore}
            >
              {text.ui.showMoreBtn}
            </button>
            <button className="reset backToTop" onClick={handleBackToTop}>
              {text.ui.bttBtn}
            </button>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query HomeQuery($locale: String!) {
    contentful {
      coins: databaseCoinCollection(
        limit: 1000
        where: { country: "Finland" }
      ) {
        items {
          slug(locale: $locale)
          country
          currency
          currencyDropdown
          designer
          faceValue
          featureImage
          characteristicWeight
          characteristicDiameter
          characteristicMaterial
          characteristicMetal
          characteristicThickness
          title(locale: $locale)
          descriptionText(locale: $locale)
          series
          seriesRefCollection(limit: 3) {
            items {
              serieName
            }
          }
          topic
          years
          commemorative
          imagesCollection(limit: 5) {
            items {
              position
              imageUrl
            }
          }
          coinid: sys {
            id
          }
        }
      }
      etusivu: kolikkogalleriaMuuSislt(
        id: "798WUDW9uxJnMHJ4pvzdfg"
        locale: $locale
      ) {
        heroYlotsikko
        heroOtsikko
        heroIngressi {
          json
        }
      }
      series: seriesCollection {
        items {
          nameFi: serieName(locale: "fi-FI")
          nameEn: serieName(locale: "en-US")
          useInFilters
        }
      }
    }
  }
`;

export default Index;
