import React from "react";
import ReactDOM from "react-dom";
import "styled-components";
import theme from "../styles/theme";

const Modal = ({ children, onClose, showMe }) => {
  const [target] = React.useState(() => document.createElement("div"));

  React.useEffect(() => {
    document.body.appendChild(target);
    const rect = document.body.getBoundingClientRect();
    document.body.style["overflow-y"] = "hidden";
    //eslint-disable-next-line no-unused-expressions
    document.body.scrollTop;
    const rect2 = document.body.getBoundingClientRect();
    document.body.style["padding-right"] = `${rect2.width - rect.width}px`;
    return () => {
      document.body.removeChild(target);
      document.body.style["overflow-y"] = "";
      document.body.style["padding-right"] = "";
    };
  }, [target]);

  return ReactDOM.createPortal(
    <div
      onMouseDown={e => {
        if (e.target !== e.currentTarget) {
          return;
        }
        e.preventDefault();
      }}
      css={`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        outline: 0;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.25);
        transition: all 0.4s;
      `}
    >
      <div
        css={`
          width: 100%;
          max-width: 90vw;
          max-height: 90vh;
          background: #fff;
          position: relative;
          boxshadow: 0px 0px 32px rgba(0, 0, 0, 0.125);
          border-radius: 12px;
          z-index: 90;
          top: 0;
          left: 0;
          padding: 10px 0;
          display: flex;
          flex-direction: column;
          .contentBox {
            padding: 10px 40px;
            overflow-y: scroll;
          }
          .CloseBtn {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 10;
          }
          ${theme.mobile} {
            max-width: 100%;
            max-height: 100%;
            border-radius: 0;
            .contentBox {
              padding: 10px;
            }
          }
        `}
      >
        <button
          className="CloseBtn reset"
          onClick={e => {
            e.preventDefault();
            onClose();
          }}
        >
          <svg
            width="34"
            height="34"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="black"
              fill-opacity="0.54"
            />
          </svg>
        </button>
        <div className="contentBox">{children}</div>
      </div>
    </div>,
    target
  );
};

export default Modal;
