import React, { useState } from "react";
import Select from "react-dropdown-select";
import "styled-components";
import theme from "../styles/theme";

const Filters = ({ selections, handleSelect, text, clearFilters, series }) => {
  const [showFilters, setShowFilters] = useState(false);
  let showClearBtn = false;

  if (
    selections.endYear ||
    selections.startYear ||
    selections.currency ||
    selections.faceValue ||
    selections.series ||
    selections.type ||
    selections.material
  ) {
    showClearBtn = true;
  }

  const material = [
    { value: "Silver", label: text.filters.options.material.silver },
    { value: "Gold", label: text.filters.options.material.gold },
    {
      value: "Base metal",
      label: text.filters.options.material.baseMetal,
    },
  ];

  const type = [
    {
      value: "Juhlaraha",
      label: text.filters.options.type.commemorative,
    },
    {
      value: "Käyttöraha",
      label: text.filters.options.type.spendingMoney,
    },
  ];

  const euros = [
    {
      value: { currency: "euro", faceValue: "0,01" },
      label: text.filters.options.faceValue.cent_1,
    },
    {
      value: { currency: "euro", faceValue: "0,02" },
      label: text.filters.options.faceValue.cent_2,
    },
    {
      value: { currency: "euro", faceValue: "0,05" },
      label: text.filters.options.faceValue.cent_5,
    },
    {
      value: { currency: "euro", faceValue: "0,10" },
      label: text.filters.options.faceValue.cent_10,
    },
    {
      value: { currency: "euro", faceValue: "0.20" },
      label: text.filters.options.faceValue.cent_20,
    },
    {
      value: { currency: "euro", faceValue: "0.50" },
      label: text.filters.options.faceValue.cent_50,
    },
    {
      value: { currency: "euro", faceValue: 1 },
      label: text.filters.options.faceValue.euro_1,
    },
    {
      value: { currency: "euro", faceValue: 2 },
      label: text.filters.options.faceValue.euro_2,
    },
    {
      value: { currency: "euro", faceValue: 5 },
      label: text.filters.options.faceValue.euro_5,
    },
    {
      value: { currency: "euro", faceValue: 10 },
      label: text.filters.options.faceValue.euro_10,
    },
    {
      value: { currency: "euro", faceValue: 20 },
      label: text.filters.options.faceValue.euro_20,
    },
    {
      value: { currency: "euro", faceValue: 50 },
      label: text.filters.options.faceValue.euro_50,
    },
    {
      value: { currency: "euro", faceValue: 100 },
      label: text.filters.options.faceValue.euro_100,
    },
  ];

  const marks = [
    {
      value: { currency: "markka", faceValue: "0,01" },
      label: text.filters.options.faceValue.penny_1,
    },
    {
      value: { currency: "markka", faceValue: "0,05" },
      label: text.filters.options.faceValue.penny_5,
    },
    {
      value: { currency: "markka", faceValue: "0,10" },
      label: text.filters.options.faceValue.penny_10,
    },
    {
      value: { currency: "markka", faceValue: "0,20" },
      label: text.filters.options.faceValue.penny_20,
    },
    {
      value: { currency: "markka", faceValue: "0,50" },
      label: text.filters.options.faceValue.penny_50,
    },
    {
      value: { currency: "markka", faceValue: 1 },
      label: text.filters.options.faceValue.mark_1,
    },
    {
      value: { currency: "markka", faceValue: 5 },
      label: text.filters.options.faceValue.mark_5,
    },
    {
      value: { currency: "markka", faceValue: 10 },
      label: text.filters.options.faceValue.mark_10,
    },
    {
      value: { currency: "markka", faceValue: 20 },
      label: text.filters.options.faceValue.mark_20,
    },
  ];

  const faceValues = [
    {
      value: { currency: "euro", faceValue: "0,01" },
      label: text.filters.options.faceValue.cent_1,
    },
    {
      value: { currency: "euro", faceValue: "0,02" },
      label: text.filters.options.faceValue.cent_2,
    },
    {
      value: { currency: "euro", faceValue: "0,05" },
      label: text.filters.options.faceValue.cent_5,
    },
    {
      value: { currency: "euro", faceValue: "0,10" },
      label: text.filters.options.faceValue.cent_10,
    },
    {
      value: { currency: "euro", faceValue: "0.20" },
      label: text.filters.options.faceValue.cent_20,
    },
    {
      value: { currency: "euro", faceValue: "0.50" },
      label: text.filters.options.faceValue.cent_50,
    },
    {
      value: { currency: "euro", faceValue: 1 },
      label: text.filters.options.faceValue.euro_1,
    },
    {
      value: { currency: "euro", faceValue: 2 },
      label: text.filters.options.faceValue.euro_2,
    },
    {
      value: { currency: "euro", faceValue: 5 },
      label: text.filters.options.faceValue.euro_5,
    },
    {
      value: { currency: "euro", faceValue: 10 },
      label: text.filters.options.faceValue.euro_10,
    },
    {
      value: { currency: "euro", faceValue: 20 },
      label: text.filters.options.faceValue.euro_20,
    },
    {
      value: { currency: "euro", faceValue: 50 },
      label: text.filters.options.faceValue.euro_50,
    },
    {
      value: { currency: "euro", faceValue: 100 },
      label: text.filters.options.faceValue.euro_100,
    },

    {
      value: { currency: "markka", faceValue: "0,01" },
      label: text.filters.options.faceValue.penny_1,
    },
    {
      value: { currency: "markka", faceValue: "0,05" },
      label: text.filters.options.faceValue.penny_5,
    },
    {
      value: { currency: "markka", faceValue: "0,10" },
      label: text.filters.options.faceValue.penny_10,
    },
    {
      value: { currency: "markka", faceValue: "0,20" },
      label: text.filters.options.faceValue.penny_20,
    },
    {
      value: { currency: "markka", faceValue: "0,50" },
      label: text.filters.options.faceValue.penny_50,
    },
    {
      value: { currency: "markka", faceValue: 1 },
      label: text.filters.options.faceValue.mark_1,
    },
    {
      value: { currency: "markka", faceValue: 5 },
      label: text.filters.options.faceValue.mark_5,
    },
    {
      value: { currency: "markka", faceValue: 10 },
      label: text.filters.options.faceValue.mark_10,
    },
    {
      value: { currency: "markka", faceValue: 20 },
      label: text.filters.options.faceValue.mark_20,
    },
  ];

  const sortByOptions = [
    { value: "yearsDesc", label: text.sorting.latest },
    { value: "yearsAsc", label: text.sorting.oldest },
    { value: "valueDesc", label: text.sorting.maxValue },
    { value: "valueAsc", label: text.sorting.minValue },
  ];

  let filteredFaceValues = [...faceValues];
  let filteredCurrency = [
    { value: "euro", label: "Euro" },
    { value: "markka", label: "Markka" },
  ];

  if (selections.startYear > 2001 || selections.currency === "euro") {
    filteredFaceValues = [...euros];
    filteredCurrency = [{ value: "euro", label: "Euro" }];
  }
  if (selections.endYear < 1999 && selections.endYear > 1) {
    filteredFaceValues = [...marks];
    filteredCurrency = [{ value: "markka", label: "Markka" }];
  }
  if (selections.currency === "markka") {
    filteredFaceValues = [...marks];
  }

  return (
    <div
      css={`
        display: flex;
        padding-bottom: 40px;
        width: 100%;
        align-items: center;
        text-align: left;
        box-sizing: border-box;
        flex-direction: column;

        .showFiltersBtn {
          display: none;
          margin-top: 15px;
          padding: 5px 10px;
          align-items: center;
          opacity: 0.6;
          font-size: 16px;
          color: #000;
          text-decoration: underline;
          width: 220px;
          svg {
            margin-right: 5px;
          }
        }
        .clearBtn {
          display: inline-flex;
          visibility: hidden;
          margin-top: 25px;
          padding: 5px 10px;
          align-items: center;
          font-size: 13px;
          color: rgba(0, 0, 0, 0.8);
          text-transform: uppercase;
          background: #f2f2f2;
          border-radius: 3px;
          &:hover {
            background: #f7f7f7;
          }
          svg {
            margin-right: 5px;
            width: 16px;
            path {
              fill: rgba(0, 0, 0, 0.8);
            }
          }
        }
        .show {
          visibility: visible;
          animation-name: slideIn;
          animation-duration: 0.5s;
          opacity: 0.6;
        }
        .hide {
          animation-name: slideOut;
          animation-duration: 0.5s;
          opacity: 0;
        }
        @keyframes slideIn {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 0.6;
            transform: translateY(0);
          }
        }
        @keyframes slideOut {
          from {
            opacity: 0.6;
            transform: translateY(-20px);
          }
          to {
            opacity: 0;
            transform: translateY(0);
          }
        }
        .filtersBox {
          width: 100%;
          display: flex;
        }
        .hidden {
          display: none;
        }
        .react-dropdown-select-input {
          font-size: 16px;
          font-family: "nimbus-sans";
        }
        .react-dropdown-select {
          height: 40px;
          padding: 5px 10px;
        }
        .react-dropdown-select-content span {
          display: inline-flex;
          align-items: center;
        }
        .react-dropdown-select:hover,
        .react-dropdown-select:focus {
          border-color: rgba(137, 55, 185, 1);
        }
        .react-dropdown-select:focus-within {
          border-color: rgba(137, 55, 185, 1);
          box-shadow: 0 0 0 3px rgb(137 55 185 / 20%);
        }
        .react-dropdown-select-item {
          padding: 15px 10px;
        }
        .react-dropdown-select-item:hover,
        .react-dropdown-select-item:focus {
          background: rgba(137, 55, 185, 0.1);
        }
        .react-dropdown-select-item.react-dropdown-select-item-selected {
          background: rgba(137, 55, 185, 1);
        }
        .react-dropdown-select-clear {
          font-weight: 500;
          font-size: 25px;
          &:hover {
            color: rgba(0, 0, 0, 0.7);
          }
        }
        ${theme.mobile} {
          position: relative;
          .showFiltersBtn {
            display: flex;
          }
          .react-dropdown-select-input {
            font-size: 18px;
          }
          .filtersBox {
            flex-direction: column;
          }
          .react-dropdown-select {
            height: 52px;
          }
          .react-dropdown-select-clear {
            font-size: 30px;
          }
        }
      `}
    >
      <div className="filtersBox">
        <div
          className={showFilters ? "filtersOpen" : "filtersClosed"}
          css={`
            display: grid;
            width: 100%;
            grid-gap: 20px;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-template-areas:
              "startYear endYear currency faceValue"
              "series type material sortBy";
            ${theme.tablet} {
              grid-template-columns: repeat(3, 1fr);
              grid-template-rows: auto;
              grid-gap: 15px;
              grid-template-areas:
                "startYear endYear currency"
                "faceValue series type"
                "material sortBy .";
            }
            ${theme.mobile} {
              grid-template-columns: repeat(1, 1fr);
              grid-template-rows: auto;
              grid-gap: 15px;
              grid-template-areas:
                "startYear"
                "endYear"
                "sortBy";
              > div:nth-child(3),
              > div:nth-child(4),
              > div:nth-child(5),
              > div:nth-child(6),
              > div:nth-child(7) {
                display: none;
              }
              &.filtersOpen {
                grid-template-areas:
                  "startYear"
                  "endYear"
                  "currency"
                  "faceValue"
                  "series"
                  "type"
                  "material"
                  "sortBy";
                > div:nth-child(3),
                > div:nth-child(4),
                > div:nth-child(5),
                > div:nth-child(6),
                > div:nth-child(7) {
                  display: block;
                }
              }
            }
          `}
        >
          <div
            css={`
              grid-area: startYear;
              .react-dropdown-select-clear {
                display: ${selections.startYear ? "block" : "none"};
              }
            `}
          >
            <Select
              clearable
              type="text"
              onChange={value => handleSelect(value, "startYear")}
              values={
                selections.startYear
                  ? [
                      {
                        value: selections.startYear,
                        label: `${selections.startYear} →`,
                      },
                    ]
                  : []
              }
              options={
                selections.endYear
                  ? yearsFrom.filter(item => item.value <= selections.endYear)
                  : yearsFrom
              }
              placeholder={text.filters.yearStart}
              searchable={false}
            />
          </div>
          <div
            css={`
              grid-area: endYear;
              .react-dropdown-select-clear {
                display: ${selections.endYear ? "block" : "none"};
              }
            `}
          >
            <Select
              clearable
              label={text.filters.yearEnd}
              onChange={value => handleSelect(value, "endYear")}
              values={
                selections.endYear
                  ? [
                      {
                        value: selections.endYear,
                        label: `← ${selections.endYear}`,
                      },
                    ]
                  : []
              }
              options={
                selections.startYear
                  ? yearsTo.filter(item => item.value >= selections.startYear)
                  : yearsTo
              }
              placeholder={text.filters.yearEnd}
              searchable={false}
            />
          </div>
          <div
            css={`
              grid-area: currency;
              .react-dropdown-select-clear {
                display: ${selections.currency ? "block" : "none"};
              }
            `}
          >
            <Select
              options={filteredCurrency}
              values={
                selections.currency
                  ? [
                      {
                        value: selections.currency,
                        label: filteredCurrency.find(
                          item => item.value === selections.currency
                        ).label,
                      },
                    ]
                  : []
              }
              onChange={value => {
                handleSelect(value, "currency");
              }}
              placeholder={text.filters.options.currency}
              clearable
              searchable={false}
            />
          </div>
          <div
            css={`
              grid-area: faceValue;
              .react-dropdown-select-clear {
                display: ${selections.faceValue ? "block" : "none"};
              }
            `}
          >
            <Select
              classNamePrefix="rs"
              options={filteredFaceValues}
              values={
                selections.faceValue
                  ? [
                      {
                        value: selections.faceValue,
                        label: faceValues.find(
                          item =>
                            item?.value.currency ===
                              selections.currencyHidden &&
                            item?.value.faceValue == selections.faceValue
                        )?.label,
                      },
                    ]
                  : []
              }
              onChange={value => {
                handleSelect(value, "faceValue");
              }}
              placeholder={text.filters.faceValue}
              clearable
              searchable={false}
            />
          </div>

          <div
            css={`
              grid-area: series;
              .react-dropdown-select-dropdown {
                max-height: none;
              }
              .react-dropdown-select-clear {
                display: ${selections.series ? "block" : "none"};
              }
              .react-dropdown-select-content span {
                @media (max-width: 1300px) {
                  position: relative;
                  font-size: 14px;

                  white-space: nowrap;
                  overflow: hidden;
                  width: 90%;
                  &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 15px;
                    height: 30px;
                    background: linear-gradient(
                      90deg,
                      rgba(255, 255, 255, 0) 0%,
                      rgba(255, 255, 255, 0.8) 100%
                    );
                  }
                }
                @media (max-width: 600px) {
                  font-size: 16px;
                  width: auto;

                  &:after {
                    width: 1px;
                  }
                }
              }
            `}
          >
            <Select
              classNamePrefix="rs"
              placeholder={text.filters.series}
              values={
                selections.series
                  ? [
                      {
                        value: selections.series,
                        label: series.find(
                          item => item.value === selections.series
                        ).label,
                      },
                    ]
                  : []
              }
              onChange={value => handleSelect(value, "series")}
              options={series}
              searchable={false}
              clearable
            />
          </div>
          <div
            css={`
              grid-area: type;
              .react-dropdown-select-clear {
                display: ${selections.type ? "block" : "none"};
              }
            `}
          >
            <Select
              classNamePrefix="rs"
              placeholder={text.filters.type}
              values={
                selections.type
                  ? [
                      {
                        value: selections.type,
                        label: type.find(item => item.value === selections.type)
                          .label,
                      },
                    ]
                  : []
              }
              onChange={value => handleSelect(value, "type")}
              options={type}
              clearable
              searchable={false}
            />
          </div>
          <div
            css={`
              grid-area: material;
              .react-dropdown-select-clear {
                display: ${selections.material ? "block" : "none"};
              }
            `}
          >
            <Select
              classNamePrefix="rs"
              placeholder={text.filters.metal}
              values={
                selections.material
                  ? [
                      {
                        value: selections.material,
                        label: material.find(
                          item => item.value === selections.material
                        ).label,
                      },
                    ]
                  : []
              }
              onChange={value => handleSelect(value, "material")}
              options={material}
              clearable
              searchable={false}
            />
          </div>
          <div
            css={`
              grid-area: sortBy;
            `}
          >
            <Select
              placeholder={text.filters.metal}
              onChange={value => handleSelect(value, "sortBy")}
              options={sortByOptions}
              searchable={false}
              values={
                selections.sortBy
                  ? [
                      {
                        value: selections.sortBy,
                        label: sortByOptions.find(
                          item => item.value === selections.sortBy
                        ).label,
                      },
                    ]
                  : [{ value: "yearsDesc", label: text.sorting.latest }]
              }
            />
          </div>
        </div>
      </div>

      <button
        className={`reset clearBtn ${showClearBtn ? "show" : "hide"}`}
        onClick={clearFilters}
      >
        <SvgClear />
        {text.filters.clearBtn}
      </button>

      <button
        className="reset showFiltersBtn"
        onClick={() => setShowFilters(!showFilters)}
      >
        <SvgFilters />
        {showFilters
          ? text.filters.hideFiltersBtn
          : text.filters.showFiltersBtn}
      </button>
    </div>
  );
};

export default Filters;

const yearsTo = [...Array(2022 - 1863).keys()]
  .map(item => ({
    value: item + 1863,
    label: `← ${item + 1863}`,
  }))
  .reverse();

const yearsFrom = [...Array(2022 - 1863).keys()]
  .map(item => ({
    value: item + 1863,
    label: `${item + 1863} →`,
  }))
  .reverse();

const SvgClear = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.34999 6.35C7.79999 4.9 9.78999 4 12 4C16.42 4 19.99 7.58 19.99 12C19.99 16.42 16.42 20 12 20C8.26999 20 5.15999 17.45 4.26999 14H6.34999C7.16999 16.33 9.38999 18 12 18C15.31 18 18 15.31 18 12C18 8.69 15.31 6 12 6C10.34 6 8.85999 6.69 7.77999 7.78L11 11H3.99999V4L6.34999 6.35Z"
      fill="black"
    />
  </svg>
);

const SvgFilters = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="15"
      height="15"
      viewBox="0 0 512 312"
    >
      <g>
        <g>
          <g>
            <path
              d="M85.333,170.667c39.765,0,73.173-27.193,82.645-64h322.688c11.782,0,21.333-9.551,21.333-21.333
				C512,73.551,502.449,64,490.667,64H167.979c-9.472-36.807-42.88-64-82.645-64C38.202,0,0,38.202,0,85.333
				S38.202,170.667,85.333,170.667z M85.333,42.667c23.567,0,42.667,19.099,42.667,42.667S108.901,128,85.333,128
				s-42.667-19.099-42.667-42.667S61.766,42.667,85.333,42.667z"
            />

            <path
              d="M490.667,234.667H338.645c-9.472-36.807-42.88-64-82.645-64s-73.173,27.193-82.645,64H21.333
				C9.551,234.667,0,244.218,0,256s9.551,21.333,21.333,21.333h152.021c9.472,36.807,42.88,64,82.645,64s73.173-27.193,82.645-64
				h152.021c11.782,0,21.333-9.551,21.333-21.333S502.449,234.667,490.667,234.667z M256,298.667
				c-23.567,0-42.667-19.099-42.667-42.667s19.099-42.667,42.667-42.667s42.667,19.099,42.667,42.667S279.567,298.667,256,298.667z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

const UndoSvg = () => {
  return (
    <svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.44451 13.75H22C26.5563 13.75 30.25 17.4437 30.25 22C30.25 26.5564 26.5563 30.25 22 30.25H18.5625V27.5H22C25.0375 27.5 27.5 25.0376 27.5 22C27.5 18.9624 25.0375 16.5 22 16.5H7.44451L13.3472 22.4027L11.4027 24.3473L2.18042 15.125L11.4027 5.90274L13.3472 7.84728L7.44451 13.75Z"
      />
    </svg>
  );
};
